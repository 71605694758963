.twofa-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.twofa-input {
    width: 80px;
    height: 80px;
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    margin-right: 15px;
    text-transform: uppercase;
    border: 1px solid $darkblue;
    &.active {
        background-color: $darkblue;
        color: white;
        font-size: 2rem;
    }
}
@media(max-width:992px) {
    .twofa-input {
        width: 40px;
        height: 40px;
        padding: 4px;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 10px;
        text-transform: uppercase;
        border: 1px solid $darkblue;
        &.active {
            background-color: $darkblue;
            color: white;
            font-size: 1.2rem;
        }
    }
}
@media(max-width:425px) {
    .twofa-input {
        width: 25px;
        height: 25px;
        padding: 2px;
        border-radius: 2px;
        font-size: 12px;
        margin-right: 5px;
        text-transform: uppercase;
        border: 1px solid $darkblue;
        &.active {
            background-color: $darkblue;
            color: white;
            font-size: .7rem;
        }
    }
}